import React from 'react';
import { Slider } from '../components/slider';
// import { Scroll_Butons } from '../components/scroll_butons';
// import { Tabs_Sports } from '../components/tabs_sports.js';
// import { Resultados_2 } from '../components/resultados_2';
import { Servicios_Form_2 } from '../components/servicios_form_2';
import { Noticias_Form_1 } from '../components/noticias_form_1';
export const Home = () => {
    return (
        <div>
            <div className="container-fluid bg-white">
                <div className="row">
                   
                        <Noticias_Form_1 />
                 
                    {/* <div className="col-lg-2">
                        <Resultados_2 />
                    </div> */}
                </div>
            </div>
            <Slider />
            {/* <div className="container-fluid my-5">
                <div className="row g-10">
                    <div className="col-lg-1">
                        <Scroll_Butons />
                    </div>
                    <div className="col-lg-11 overflow-hidden">
                        <Tabs_Sports />
                    </div>
                </div>
            </div> */}
            <Servicios_Form_2 />
        </div>
    )
}
